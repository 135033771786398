import type { Except, SetRequired, Merge } from 'type-fest'
import type {
    UseMutationResult,
    UseInfiniteQueryResult,
    InfiniteData,
    UseInfiniteQueryOptions,
    UseQueryResult,
    UseQueryOptions,
} from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type dayjs from 'dayjs'
import type { Session } from 'next-auth'
import { useInfiniteQuery, useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { get, post, serverGet } from '../api'

export type IntelFormTextGrade = Except<DTO.TextGrade, 'type' | 'subCategory'> & {
    id: string
    type: DTO.TextGrade['type'] | ''
}
export type EditIntelReport = SetRequired<CreateIntelReport, 'postID'>
export type CreateIntelReport = Pick<
    DTO.CreateIntelReport,
    'type' | 'status' | 'level' | 'tags' | 'attachments' | 'textGrades' | 'postedAt'
> & {
    textGrades: IntelFormTextGrade[]
} & {
    [T in keyof Except<DTO.CreateIntelReport, 'type' | 'status' | 'level' | 'tags' | 'postedAt' | 'attachments'>]?:
        | DTO.CreateIntelReport[T]
        | ''
}

export type CreateScoutingSettings = Except<DTO.ScoutingSettings, 'type' | 'date'> & {
    type: DTO.ScoutingSettings['type'] | ''
    date: dayjs.Dayjs | null
}
export type EditScoutingReport = SetRequired<CreateScoutingReport, 'postID'>
export type CreateScoutingReport = Pick<
    DTO.CreateScoutingPost,
    'type' | 'status' | 'level' | 'tags' | 'attachments' | 'postedAt'
> & {
    settings: CreateScoutingSettings[]
    playerLists: (string | Merge<DTO.PlayerList, { name: Enum.PlayerListName | '' }>)[]
    isOnNBARoster: boolean
    isProScout: boolean
    skillsRecordedPastThirtyDays: boolean
    isAnyPositionFilledOut: boolean
} & {
    [T in keyof Except<
        DTO.CreateScoutingPost,
        'type' | 'status' | 'level' | 'tags' | 'postedAt' | 'settings' | 'playerLists' | 'attachments'
    >]?: DTO.CreateScoutingPost[T] | ''
}

export type CreatePlayerDevelopmentReport = Pick<
    DTO.PlayerDevelopmentReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<DTO.PlayerDevelopmentReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'>]?:
        | DTO.PlayerDevelopmentReport[T]
        | ''
}

export type CreateCommunityReport = Pick<
    DTO.CommunityReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<DTO.CommunityReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'>]?:
        | DTO.CommunityReport[T]
        | ''
}

export type CreateGameReport = Pick<DTO.GameReport, 'type' | 'status' | 'tags' | 'attachments' | 'postedAt'> & {
    gameStatus: Enum.GameStatus | null
} & {
    [T in keyof Except<DTO.GameReport, 'type' | 'status' | 'tags' | 'postedAt' | 'attachments'>]?:
        | DTO.GameReport[T]
        | ''
}

export type CreateWorkoutReport = Pick<
    DTO.CreateWorkoutReport,
    'type' | 'status' | 'tags' | 'attachments' | 'postedAt'
> & {
    [T in keyof Except<
        DTO.CreateWorkoutReport,
        'type' | 'status' | 'tags' | 'attachments' | 'postedAt' | 'participants'
    >]?: DTO.CreateWorkoutReport[T] | ''
} & {
    participants: (Pick<DTO.PlayerTag, 'name' | 'teamId'> & DTO.CreateWorkoutReport['participants'][number])[]
}

export type PaginatedPostsResp = {
    results: DTO.Post[]
    info: { nextPage: number; hasNextPage: boolean }
}

export type PaginatedIntelTextTypeReportResp = {
    results: DTO.IntelTextTypeReport[]
    info: { nextPage: number; hasNextPage: boolean }
}

export type InfiniteQueryPaginatedPostsResp = InfiniteData<PaginatedPostsResp>

export const useCreateIntelReport = (): UseMutationResult<JSONResponse<DTO.Entity[]>, Error, CreateIntelReport> => {
    const queryClient = useQueryClient()
    return useMutation((report: CreateIntelReport) => post<DTO.Entity[], CreateIntelReport>('/posts/intel', report), {
        onSettled: () => queryClient.invalidateQueries(['posts']),
    })
}
export const useCreateIntelReportDraft = (): UseMutationResult<
    JSONResponse<DTO.Entity[]>,
    Error,
    CreateIntelReport
> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateIntelReport) => post<DTO.Entity[], CreateIntelReport>('/posts/intel/draft', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreateScoutingReport = (): UseMutationResult<JSONResponse, Error, CreateScoutingReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateScoutingReport) => post<undefined, CreateScoutingReport>('/posts/scouting', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}
export const useCreateScoutingReportDraft = (): UseMutationResult<JSONResponse, Error, CreateScoutingReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateScoutingReport) => post<undefined, CreateScoutingReport>('/posts/scouting/draft', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreatePlayerDevelopmentReport = (): UseMutationResult<
    JSONResponse,
    Error,
    CreatePlayerDevelopmentReport
> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreatePlayerDevelopmentReport) =>
            post<undefined, CreatePlayerDevelopmentReport>('/posts/player-development', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreatePlayerDevelopmentReportDraft = (): UseMutationResult<
    JSONResponse,
    Error,
    CreatePlayerDevelopmentReport
> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreatePlayerDevelopmentReport) =>
            post<undefined, CreatePlayerDevelopmentReport>('/posts/player-development/draft', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreateCommunityReport = (): UseMutationResult<JSONResponse, Error, CreateCommunityReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateCommunityReport) => post<undefined, CreateCommunityReport>('/posts/community', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreateCommunityReportDraft = (): UseMutationResult<JSONResponse, Error, CreateCommunityReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateCommunityReport) => post<undefined, CreateCommunityReport>('/posts/community/draft', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreateGameReport = (): UseMutationResult<JSONResponse, Error, CreateGameReport> => {
    const queryClient = useQueryClient()
    return useMutation((report: CreateGameReport) => post<undefined, CreateGameReport>('/posts/game', report), {
        onSettled: () => queryClient.invalidateQueries(['posts']),
    })
}

export const useCreateGameReportDraft = (): UseMutationResult<JSONResponse, Error, CreateGameReport> => {
    const queryClient = useQueryClient()
    return useMutation((report: CreateGameReport) => post<undefined, CreateGameReport>('/posts/game/draft', report), {
        onSettled: () => queryClient.invalidateQueries(['posts']),
    })
}

export const useCreateWorkoutReport = (): UseMutationResult<JSONResponse, Error, CreateWorkoutReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateWorkoutReport) => post<undefined, CreateWorkoutReport>('/posts/workout', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

export const useCreateWorkoutReportDraft = (): UseMutationResult<JSONResponse, Error, CreateWorkoutReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: CreateWorkoutReport) => post<undefined, CreateWorkoutReport>('/posts/workout/draft', report),
        {
            onSettled: () => queryClient.invalidateQueries(['posts']),
        }
    )
}

const queryAllPosts = async (
    type: Enum.PostFilterWithFollowing | undefined,
    search: Except<DTO.AdvancedPostSearch, 'type'>
): Promise<PaginatedPostsResp> => (await get<PaginatedPostsResp>('/posts', { ...search, type })).data
export const serverQueryAllPosts = async (
    type: Enum.PostFilterWithFollowing | undefined,
    search: Except<DTO.AdvancedPostSearch, 'type'>,
    session: Session | null
): Promise<PaginatedPostsResp> => (await serverGet<PaginatedPostsResp>('/posts', session, { ...search, type })).data
export const useQueryAllPosts = (
    type: Enum.PostFilterWithFollowing | undefined | '',
    searchParams?: Except<DTO.AdvancedPostSearch, 'page'>,
    options?: Pick<UseInfiniteQueryOptions<PaginatedPostsResp>, 'enabled'>
): UseInfiniteQueryResult<PaginatedPostsResp> =>
    useInfiniteQuery(
        ['posts', { ...searchParams, type: type || undefined }],
        ({ pageParam = 1 }) => queryAllPosts(type || undefined, { ...searchParams, page: pageParam as number }),
        {
            ...options,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? Number(lastPage.info.nextPage) : false),
        }
    )

const queryPostsByEntity = async (
    entityType: DTO.SearchResultType,
    entityId: string | undefined,
    type: Enum.PostFilter | undefined,
    search: Except<DTO.AdvancedPostSearch, 'type'>
): Promise<PaginatedPostsResp> =>
    (await get<PaginatedPostsResp>(`/posts/${entityType}/${entityId || ''}`, { ...search, type })).data

export const useQueryPostsByEntity = (
    entityType: DTO.SearchResultType,
    entityId: string | undefined,
    type: Enum.PostFilter | undefined | '',
    searchParams?: Except<DTO.AdvancedPostSearch, 'page'>
): UseInfiniteQueryResult<PaginatedPostsResp> =>
    useInfiniteQuery(
        ['posts', entityType, entityId, { ...searchParams, type: type || undefined }],
        ({ pageParam = 1 }) =>
            queryPostsByEntity(entityType, entityId, type || undefined, { ...searchParams, page: pageParam as number }),
        {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? Number(lastPage.info.nextPage) : false),
            enabled: !!entityId,
        }
    )

export const useGetIntelTextTypes = (
    options?: Except<UseQueryOptions<DTO.IntelTextTypes[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IntelTextTypes[]> =>
    useQuery<DTO.IntelTextTypes[]>(
        ['intel-text-types'],
        async () => (await get<DTO.IntelTextTypes[]>('/posts/intel/text-types')).data,
        options
    )

const queryIntelTextTypeFeed = async (
    playerId: string,
    dateRange: Enum.DateRanges | undefined,
    intelTextTypes?: Enum.IntelTextTypes[]
): Promise<DTO.IntelTextTypeReport[]> =>
    (await get<DTO.IntelTextTypeReport[]>(`/posts/intel/player/${playerId}`, { dateRange, intelTextTypes })).data

export const useQueryIntelTextTypeFeed = (
    playerId: string | undefined,
    dateRange?: Enum.DateRanges,
    intelTextTypes?: Enum.IntelTextTypes[],
    options?: Omit<
        UseQueryOptions<DTO.IntelTextTypeReport[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.IntelTextTypeReport[]> =>
    useQuery<DTO.IntelTextTypeReport[]>(
        ['posts', playerId, intelTextTypes, dateRange],
        // @ts-expect-error playerId  will only get referenced here when it is defined (enabled)
        () => queryIntelTextTypeFeed(playerId, dateRange, intelTextTypes),
        {
            enabled: !!playerId,
            ...options,
        }
    )

const queryScoutingLocBoxScores = async (
    playerId: string,
    minDate?: string | undefined
): Promise<DTO.ScoutingLocBoxScore[]> =>
    (await get<DTO.ScoutingLocBoxScore[]>(`/posts/scouting/locs/${playerId}`, { minDate })).data

export const serverGetScoutingLocBoxScores = async (
    playerId: string,
    session: Session | null,
    minDate?: string | undefined
): Promise<DTO.ScoutingLocBoxScore[]> =>
    (await serverGet<DTO.ScoutingLocBoxScore[]>(`/posts/scouting/locs/${playerId}`, session, { minDate })).data

export const useQueryScoutingLocBoxScores = (
    playerId: string | undefined,
    minDate?: string,
    options?: Omit<
        UseQueryOptions<DTO.ScoutingLocBoxScore[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScoutingLocBoxScore[]> =>
    useQuery<DTO.ScoutingLocBoxScore[]>(
        ['locs-by-scout', playerId, minDate],
        // @ts-expect-error playerId  will only get referenced here when it is defined (enabled)
        () => queryScoutingLocBoxScores(playerId, minDate),
        {
            enabled: !!playerId,
            ...options,
        }
    )
