'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'

type UseShootingParamsReturn = {
    defaultParams: DTO.ShotFilters
    filterParams: DTO.ShotFilters
    setFilterParams: Dispatch<SetStateAction<DTO.ShotFilters>>
    isPrintOnly: boolean
    season: number
    defaultYears: number[]
}

export type ShotTypeOption = {
    label: string
    value: Enum.ShotType
}
export const shotTypes: ShotTypeOption[] = [
    {
        label: 'Dunk',
        value: 'DUNK',
    },
    {
        label: 'Layup',
        value: 'LAYUP',
    },
    {
        label: 'Floater',
        value: 'FLOATER',
    },
    {
        label: 'Post',
        value: 'POST',
    },
    {
        label: 'Jumper',
        value: 'JUMPER',
    },
    {
        label: 'Heave',
        value: 'HEAVE',
    },
]

export type GameStateOption = {
    label: string
    value: Enum.GameState
}

export const gameStates: GameStateOption[] = [
    {
        label: 'Half-Court',
        value: 'HALF_COURT',
    },
    {
        label: 'Putback',
        value: 'PUTBACK',
    },
    {
        label: 'Transition',
        value: 'TRANSITION',
    },
]

export const getInitFilterParams = (
    defaultParams: DTO.ShotFilters,
    queryParams: Partial<DTO.ShotFilters>
): DTO.ShotFilters => ({
    playerId: queryParams.playerId || defaultParams.playerId,
    teamId: queryParams.teamId || defaultParams.teamId,
    league: queryParams.league || defaultParams.league,
    timeFrame: queryParams.timeFrame || defaultParams.timeFrame,
    startDate: queryParams.startDate || defaultParams.startDate,
    endDate: queryParams.endDate || defaultParams.endDate,
    gameIds: (queryParams.gameIds ? JSON.parse(queryParams.gameIds as string) : defaultParams.gameIds) as string[],
    opponentTeamId: queryParams.opponentTeamId || defaultParams.opponentTeamId,
    type: queryParams.type || defaultParams.type,
    shotTypes: queryParams.shotTypes
        ? (JSON.parse(queryParams.shotTypes as string) as Enum.ShotType[])
        : defaultParams.shotTypes,
    seasons: queryParams.seasons ? (JSON.parse(queryParams.seasons as string) as number[]) : defaultParams.seasons,
    level: queryParams.level || defaultParams.level,
    gameStates: queryParams.gameStates
        ? (JSON.parse(queryParams.gameStates as string) as Enum.GameState[])
        : defaultParams.gameStates,
})

export default (
    playerId: string | null | undefined,
    teamId: string | null | undefined,
    league: Enum.League | undefined,
    season: number
): UseShootingParamsReturn => {
    const searchParams = useSearchParams()
    const queryParams = URLSearchParamsToObject(searchParams)
    const defaultYears = [season, season - 1, season - 2, season - 3, season - 4]
    const isPrintOnly = !!searchParams?.get('printOnly')

    const defaultParams = useMemo<DTO.ShotFilters>(
        () => ({
            playerId: playerId || undefined,
            teamId: teamId || undefined,
            seasons: [season],
            league: league || 'NBA',
            timeFrame: (league === 'NBA' ? 'REGULAR' : league === 'G-League' ? 'SEASON' : 'AM_SEASON') as DTO.TimeFrame,
            startDate: undefined,
            endDate: undefined,
            gameIds: [],
            playersToExclude: [],
            opponentTeamId: undefined,
            shotTypes: shotTypes.map((s) => s.value),
            type: 'offense',
            level: ['NBA', 'G-League', 'Summer'].includes(league || 'NBA') ? 'NBA' : 'FIBA',
            gameStates: gameStates.map((s) => s.value),
        }),
        [playerId, teamId, season, league]
    )

    const [filterParams, setFilterParams] = useState<DTO.ShotFilters>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    return {
        defaultParams,
        filterParams,
        setFilterParams,
        isPrintOnly,
        season,
        defaultYears,
    }
}
