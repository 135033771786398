import type { Except } from 'type-fest'
import type {
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { FollowingModalFormType } from '../../components/feed/FollowingModalForm'
import type { Session } from 'next-auth'
import type { IncompleteReportsQueryParams } from './useScoutAssignments'
import { numDaysOnIncompleteReportList } from '../../shared/constants/assignments'
import { formatDateString, pastDate } from '../../shared/utils/dates'
import dayjs from 'dayjs'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post, serverGet } from '../api'

export const getIncompleteAssignmentsQueryParams = (session: Session | null): IncompleteReportsQueryParams => {
    const isAssignmentManager = !!session?.roles.featurePermissions['assignment-schedule-management']

    const params: IncompleteReportsQueryParams = {
        startDate: formatDateString(pastDate(numDaysOnIncompleteReportList, 'days'), 'YYYY-MM-DD', 'local'),
        endDate: formatDateString(pastDate(1, 'days'), 'YYYY-MM-DD', 'local'),
    }
    if (isAssignmentManager) {
        const level: Enum.BoardLevels[] = []
        if (session.roles.contentPermissions['amateur-assignments']) level.push('Amateur')
        if (session.roles.contentPermissions['pro-assignments']) level.push('Pro')
        params.level = level
    } else {
        params.scoutId = session?.entityId
    }
    return params
}

const getUserFollowing = async () => (await get<DTO.UserFollowing>('/user-following')).data

export const serverGetUserFollowing = async (session: Session | null): Promise<DTO.UserFollowing> =>
    (await serverGet<DTO.UserFollowing>('/user-following', session)).data

export const useUserFollowing = (
    options?: Except<
        UseQueryOptions<DTO.UserFollowing, Error, DTO.UserFollowing, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary' | 'retry'
    >
): UseQueryResult<DTO.UserFollowing> =>
    useQuery<DTO.UserFollowing, Error, DTO.UserFollowing, QueryKey>(['user-following'], getUserFollowing, {
        ...options,
        retry: false,
    })

export const useSaveUserFollowing = (
    options?: Except<UseMutationOptions<JSONResponse, Error, DTO.UserFollowing>, 'mutationFn' | 'onSettled'>
): UseMutationResult<JSONResponse, Error, DTO.UserFollowing> => {
    const queryClient = useQueryClient()
    return useMutation(
        (following: DTO.UserFollowing) => post<undefined, DTO.UserFollowing>('/user-following', following),
        {
            ...options,
            onSettled: () =>
                queryClient.invalidateQueries({
                    predicate: ({ queryKey }) =>
                        (queryKey[0] === 'posts' &&
                            (queryKey[1] as DTO.AdvancedPostSearch | undefined)?.type === 'FOLLOWING') ||
                        queryKey[0] === 'user-following',
                }),
        }
    )
}

export const getInitialFollowingModalFormValues = (): FollowingModalFormType => ({
    subjects: [],
    authors: [],
    content: [],
    boards: [],
    playerLists: [],
    keywords: [],
    showActivePosts: false,
    minDate: null,
    maxDate: null,
    intelTextTypes: [],
})

export const useSearchAsUserFollowing = (
    advancedSearchParams: DTO.AdvancedPostSearch,
    options?: Except<
        UseQueryOptions<FollowingModalFormType, Error, FollowingModalFormType, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<FollowingModalFormType> =>
    useQuery<FollowingModalFormType, Error, FollowingModalFormType, QueryKey>(
        ['user-following-search', advancedSearchParams],
        async () => {
            const { data: form } = await get<DTO.UserFollowing | ''>('/user-following/search', advancedSearchParams)
            const { minDate, maxDate, intelTextTypes } = advancedSearchParams
            if (!form && !minDate && !maxDate && !intelTextTypes) return getInitialFollowingModalFormValues()
            return {
                ...(form || getInitialFollowingModalFormValues()),
                intelTextTypes: !intelTextTypes
                    ? []
                    : Array.isArray(intelTextTypes)
                    ? intelTextTypes
                    : [intelTextTypes],
                minDate: minDate ? dayjs(minDate) : null,
                maxDate: maxDate ? dayjs(maxDate) : null,
            }
        },
        {
            initialData: () =>
                !Object.keys(advancedSearchParams).length ? getInitialFollowingModalFormValues() : undefined,
            ...options,
        }
    )
