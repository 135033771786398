import type { UseQueryResult, UseQueryOptions, QueryKey, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post, remove } from '../api'

export type NoahResp = [DTO.NoahShots[], DTO.NoahShotAvgs, DTO.NoahShootingSummary | null]
export const getNoahShotsForDraftWorkout = async (playerId: string, draft: number): Promise<NoahResp> =>
    (await get<NoahResp>(`/draft-profile/${playerId}/noah-shots`, { playerId, draft })).data

export const useGetNoahShotsForDraftWorkout = (
    playerId?: string,
    draft?: number,
    options?: Omit<UseQueryOptions<NoahResp, Error, NoahResp, QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<NoahResp> =>
    useQuery<NoahResp, Error, NoahResp, QueryKey>(
        ['noah-shots', playerId, draft],
        // @ts-expect-error playerId and draft will always be defined
        () => getNoahShotsForDraftWorkout(playerId, draft),
        {
            ...options,
            enabled: !!playerId && !!draft && options?.enabled !== false,
        }
    )

export const getDraftInterview = async (playerId: string, draft: number): Promise<DTO.DraftInterview[]> =>
    (await get<DTO.DraftInterview[]>(`/draft-profile/${playerId}/draft-interview`, { playerId, draft })).data

export const useGetDraftInterview = (
    playerId?: string,
    draft?: number,
    options?: Omit<UseQueryOptions<DTO.DraftInterview[], Error, DTO.DraftInterview[], QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DraftInterview[]> =>
    useQuery<DTO.DraftInterview[], Error, DTO.DraftInterview[], QueryKey>(
        ['draft-interview', playerId, draft],
        // @ts-expect-error playerId and draft will always be defined
        () => getDraftInterview(playerId, draft),
        {
            ...options,
            enabled: !!playerId && !!draft && options?.enabled !== false,
        }
    )

export const getDraftWorkoutInfo = async (playerId: string, draft: number): Promise<DTO.DraftProfileWorkoutTracker[]> =>
    (await get<DTO.DraftProfileWorkoutTracker[]>(`/draft-profile/${playerId}/draft-workouts`, { playerId, draft })).data

export const useGetDraftWorkoutInfo = (
    playerId?: string,
    draft?: number,
    options?: Omit<
        UseQueryOptions<DTO.DraftProfileWorkoutTracker[], Error, DTO.DraftProfileWorkoutTracker[], QueryKey>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.DraftProfileWorkoutTracker[]> =>
    useQuery<DTO.DraftProfileWorkoutTracker[], Error, DTO.DraftProfileWorkoutTracker[], QueryKey>(
        ['draft-workouts', playerId, draft],
        // @ts-expect-error playerId and draft will always be defined
        () => getDraftWorkoutInfo(playerId, draft),
        {
            ...options,
            enabled: !!playerId && !!draft && options?.enabled !== false,
        }
    )

export const getDraftStaffNotes = async (
    playerId: string,
    draft: number,
    noteTypes: DTO.StaffNoteType
): Promise<DTO.DraftStaffNote[]> =>
    (await get<DTO.DraftStaffNote[]>(`/draft-profile/${playerId}/staff-notes`, { playerId, draft, noteTypes })).data

export const useGetDraftStaffNotes = (
    playerId: string | undefined,
    draft: number | undefined,
    noteTypes: DTO.StaffNoteType[] | undefined,
    options?: Omit<UseQueryOptions<DTO.DraftStaffNote[], Error, DTO.DraftStaffNote[], QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DraftStaffNote[]> =>
    useQuery<DTO.DraftStaffNote[], Error, DTO.DraftStaffNote[], QueryKey>(
        ['staff-notes', playerId, draft, noteTypes],
        // @ts-expect-error playerId and draft will always be defined
        () => getDraftStaffNotes(playerId, draft, noteTypes),
        {
            ...options,
            enabled: !!playerId && !!draft && !!noteTypes?.length && options?.enabled !== false,
        }
    )

export const useSaveDraftStaffNote = (): UseMutationResult<JSONResponse, Error, DTO.SaveDraftStaffNote> => {
    const queryClient = useQueryClient()
    return useMutation(
        (note: DTO.SaveDraftStaffNote) =>
            post<undefined, DTO.SaveDraftStaffNote>(`/draft-profile/${note.staffNoteId}/staff-notes`, note),
        {
            onSuccess: () => queryClient.invalidateQueries(['staff-notes']),
        }
    )
}

export const useAIQTesting = (
    playerId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.AIQTesting | '', Error, DTO.AIQTesting | ''>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AIQTesting | ''> =>
    useQuery<DTO.AIQTesting | '', Error, DTO.AIQTesting | ''>(
        ['aiq-testing', playerId],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        async () => (await get<DTO.AIQTesting | ''>(`/draft-profile/${playerId!}/aiq`)).data,
        {
            ...options,
            enabled: !!playerId && options?.enabled !== false,
        }
    )
export const useDeleteDraftStaffNote = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation((staffNoteId: string) => remove(`/draft-profile/${staffNoteId}/staff-notes`), {
        onSuccess: () => queryClient.invalidateQueries(['staff-notes']),
    })
}

export const getDraftWorkoutShooting = async (
    playerId: string,
    draft: number
): Promise<DTO.DraftWorkoutShooting | ''> =>
    (await get<DTO.DraftWorkoutShooting | ''>(`/draft-profile/${playerId}/shooting`, { playerId, draft })).data

export const useGetDraftWorkoutShooting = (
    playerId?: string,
    draft?: number,
    options?: Omit<
        UseQueryOptions<DTO.DraftWorkoutShooting, Error, DTO.DraftWorkoutShooting, QueryKey>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.DraftWorkoutShooting> =>
    useQuery<DTO.DraftWorkoutShooting, Error, DTO.DraftWorkoutShooting, QueryKey>(
        ['draft-workout-shooting', playerId, draft],
        // @ts-expect-error playerId and draft will always be defined
        () => getDraftWorkoutShooting(playerId, draft),
        {
            ...options,
            enabled: !!playerId && !!draft && options?.enabled !== false,
        }
    )
