import type { UseQueryResult, UseQueryOptions, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse, RequestParams } from '../api'
import type { Session } from 'next-auth'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { get, post, put, staticGet, serverGet } from '../api'

type QueryKey = ['users'] | ['usersWithUserGroups']

export const usersQueryKey: QueryKey = ['users']
export const usersWithUserGroupsQueryKey: QueryKey = ['usersWithUserGroups']

const apiPath = '/users'

export type UserQueryParams = RequestParams & {
    includeUserGroups?: string
    includeInactiveUsers?: boolean
}

export const serverQueryUsers = async <T>(session: Session | null, params: UserQueryParams = {}): Promise<T[]> =>
    (await serverGet<T[]>(apiPath, session, params)).data

export const queryUsers = async <T>(params: UserQueryParams = {}): Promise<T[]> =>
    (await get<T[]>(apiPath, params)).data

export const useQueryUsers = <T>({
    queryKey,
    params,
    options,
}: {
    queryKey: QueryKey
    params?: UserQueryParams
    options?: UseQueryOptions<T[]>
}): UseQueryResult<T[]> => useQuery<T[]>(queryKey, () => queryUsers<T>(params), options)

export const staticGetUserByEmail = async (email: string): Promise<DTO.UserWithPermissions> =>
    (await staticGet<DTO.UserWithPermissions>(`${apiPath}/by-email/${email}`)).data

export const useCreateUser = (queryKey: QueryKey): UseMutationResult<JSONResponse<DTO.User>, Error, Params.User> => {
    const queryClient = useQueryClient()
    return useMutation((newUser: Params.User) => post<DTO.User, Params.User>(apiPath, newUser), {
        onSuccess: () => queryClient.invalidateQueries(queryKey),
    })
}

export const useUpdateUser = (queryKey: QueryKey): UseMutationResult<JSONResponse<DTO.User>, Error, Params.User> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedUser: Params.User) =>
            put<DTO.User, Params.User>(`${apiPath}/${updatedUser.slug as string}`, updatedUser),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}
