import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'
import { unZipResponse } from '../utils/formatters'

export const isQueryDisabled = (filterParams: DTO.TeamStatsFilterParams): boolean =>
    (filterParams.timeFrame === 'OPPONENT' && !filterParams.opponentTeamId) ||
    (filterParams.timeFrame === 'GAME' && !filterParams.gameIds?.length) ||
    (filterParams.timeFrame === 'DATE_RANGE' && !filterParams.startDate && !filterParams.endDate)

export const getLineupBoxscores = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.LineupBoxscores[]> =>
    get<DTO.LineupBoxscores[]>(`/team-stats/${teamId}/lineups`, filters).then((resp) => resp.data)

export const useGetLineupBoxscores = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.LineupBoxscores[],
            Error,
            DTO.LineupBoxscores[],
            ['team-stats', 'lineups', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.LineupBoxscores[]> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-stats', 'lineups', teamId?.toLowerCase(), filters], () => getLineupBoxscores(teamId, filters), {
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

export const getPlayerBoxscores = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.PlayerBoxscores[]> =>
    get<DTO.PlayerBoxscores[]>(`/team-stats/${teamId}/player-boxscores`, filters).then((resp) => resp.data)

export const usePlayerBoxscores = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.PlayerBoxscores[],
            Error,
            DTO.PlayerBoxscores[],
            ['team-stats', 'player-box-scores', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.PlayerBoxscores[]> =>
    useQuery(
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        ['team-stats', 'player-box-scores', teamId?.toLowerCase(), filters],
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => getPlayerBoxscores(teamId, filters),
        {
            ...options,
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )

export const getQuarterlySummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.QuarterlySummary[]> =>
    get<DTO.QuarterlySummary[]>(`/team-stats/${teamId}/quarterly-summary`, filters).then((resp) => resp.data)

export const useQuarterlySummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.QuarterlySummary[],
            Error,
            DTO.QuarterlySummary[],
            ['team-stats', 'quarterly-summary', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.QuarterlySummary[]> =>
    useQuery(
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        ['team-stats', 'quarterly-summary', teamId?.toLowerCase(), filters],
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => getQuarterlySummary(teamId, filters),
        {
            ...options,
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )

export const getPossStartSummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.PossStartSummary[]> =>
    get<DTO.PossStartSummary[]>(`/team-stats/${teamId}/poss-start-summary`, filters).then((resp) => resp.data)

export const usePossStartSummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.PossStartSummary[],
            Error,
            DTO.PossStartSummary[],
            ['team-stats', 'poss-start-summary', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.PossStartSummary[]> =>
    useQuery(
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        ['team-stats', 'poss-start-summary', teamId?.toLowerCase(), filters],
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => getPossStartSummary(teamId, filters),
        {
            ...options,
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )

export const getShotClockSummary = (
    teamId: string,
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.ShotClockSummary[]> =>
    get<DTO.ShotClockSummary[]>(`/team-stats/${teamId}/shot-clock-summary`, filters).then((resp) => resp.data)

export const useShotClockSummary = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.ShotClockSummary[],
            Error,
            DTO.ShotClockSummary[],
            ['team-stats', 'shot-clock-summary', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.ShotClockSummary[]> =>
    useQuery(
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        ['team-stats', 'shot-clock-summary', teamId?.toLowerCase(), filters],
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => getShotClockSummary(teamId, filters),
        {
            ...options,
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )

const queryBasicStats = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamBasicStats> =>
    (await get<DTO.TeamBasicStats>(`/team-stats/${teamId}/basic-stats`, filters)).data

export const useQueryBasicStats = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamBasicStats,
            Error,
            DTO.TeamBasicStats,
            ['team-stats', 'basic-stats', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.TeamBasicStats> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-stats', 'basic-stats', teamId, filters], () => queryBasicStats(teamId, filters), {
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryTeamBoxscores = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamBoxScore[]> =>
    (await get<DTO.TeamBoxScore[]>(`/team-stats/${teamId}/team-boxscores`, filters)).data

export const useQueryTeamBoxscores = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamBoxScore[],
            Error,
            DTO.TeamBoxScore[],
            ['team-stats', 'team-boxscores', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.TeamBoxScore[]> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-stats', 'team-boxscores', teamId, filters], () => queryTeamBoxscores(teamId, filters), {
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

export const getTeamGames = (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamGames[]> =>
    get<DTO.TeamGames[]>(`/team-stats/${teamId}/team-games`, filters).then((resp) => resp.data)

export const useTeamGames = (
    teamId: string | null | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamGames[],
            Error,
            DTO.TeamGames[],
            ['team-stats', 'team-games', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.TeamGames[]> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-stats', 'team-games', teamId?.toLowerCase(), filters], () => getTeamGames(teamId, filters), {
        ...options,
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryDetailStats = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.TeamDetailStats[]> =>
    (await get<DTO.TeamDetailStats[]>(`/team-stats/${teamId}/detail-stats`, filters)).data

export const useQueryDetailStats = (
    teamId: string | undefined,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamDetailStats[],
            Error,
            DTO.TeamDetailStats[],
            ['team-stats', 'detail-stats', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.TeamDetailStats[]> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-stats', 'detail-stats', teamId, filters], () => queryDetailStats(teamId, filters), {
        enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
    })

const queryTeamShootingStats = async (
    teamId: string,
    type: 'offense' | 'defense',
    filters: DTO.TeamStatsFilterParams
): Promise<DTO.TeamShootingStats[]> =>
    (await get<DTO.TeamShootingStats[]>(`/team-stats/${teamId}/shooting-summary/${type}`, filters)).data

export const useQueryTeamShootingStats = (
    teamId: string | undefined,
    type: 'offense' | 'defense',
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamShootingStats[],
            Error,
            DTO.TeamShootingStats[],
            ['team-stats', 'team-shooting-stats', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.TeamShootingStats[]> =>
    useQuery(
        ['team-stats', 'team-shooting-stats', teamId, type, filters],
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => queryTeamShootingStats(teamId, type, filters),
        {
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )

const queryGameStatus = async (teamId: string, filters: DTO.TeamStatsFilterParams): Promise<DTO.GameDataStatusLog> =>
    (await get<DTO.GameDataStatusLog>(`/team-stats/${teamId}/game-status`, filters)).data

export const useQueryGameStatus = (
    teamId: string | null,
    filters: DTO.TeamStatsFilterParams,
    options?: Omit<
        UseQueryOptions<
            DTO.TeamShootingStats[],
            Error,
            DTO.TeamShootingStats[],
            ['team-stats', 'team-shooting-stats', string, DTO.TeamStatsFilterParams]
        >,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.GameDataStatusLog> => {
    const queryKey = ['team-stats', 'game-status', teamId, filters]
    return useQuery(
        queryKey,
        // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
        () => queryGameStatus(teamId, filters),
        {
            enabled: !!teamId && !isQueryDisabled(filters) && options?.enabled !== false,
        }
    )
}

const queryTeamShots = async (
    teamId: string | undefined,
    type: 'offense' | 'defense',
    params: DTO.ShotFilters
): Promise<DTO.Shot[]> => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const shotString = (await get<string>(`/team-stats/${teamId}/shots/${type}`, params)).data
    const shotObj = unZipResponse<DTO.Shot[]>(shotString)
    return shotObj
}

export const useTeamShots = (
    teamId: string | undefined,
    type: 'offense' | 'defense',
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.Shot[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.Shot[]> =>
    useQuery<DTO.Shot[]>(
        ['team-stats', 'team-shots', teamId, type, filters],
        () => queryTeamShots(teamId, type, filters),
        {
            ...options,
            enabled: !!teamId && options?.enabled !== false,
        }
    )

const queryTeamShotAvgs = async (league: Enum.League | undefined, params: DTO.ShotFilters): Promise<DTO.ShotAvgs[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.ShotAvgs[]>(`/team-stats/${league}/shot-avgs`, params)).data

export const useTeamShotAvgs = (
    league: Enum.League | undefined,
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.ShotAvgs[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.ShotAvgs[]> =>
    useQuery<DTO.ShotAvgs[]>(
        ['team-stats', 'team-shot-avgs', league, filters],
        () => queryTeamShotAvgs(league, filters),
        {
            ...options,
            enabled: !!league && options?.enabled !== false,
        }
    )

const queryTeamShotAvgsAndRanks = async (
    teamId: string | undefined,
    type: 'offense' | 'defense',
    params: DTO.ShotFilters
): Promise<DTO.TeamShotRanks> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.TeamShotRanks>(`/team-stats/${teamId}/shot-ranks/${type}`, params)).data

export const useTeamShotAvgsAndRanks = (
    teamId: string | undefined,
    type: 'offense' | 'defense',
    params: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.TeamShotRanks>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.TeamShotRanks> =>
    useQuery<DTO.TeamShotRanks>(
        ['team-stats', 'team-shot-ranks', teamId, type, params],
        () => queryTeamShotAvgsAndRanks(teamId, type, params),
        {
            ...options,
            enabled: !!teamId && options?.enabled !== false,
        }
    )
