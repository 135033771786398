import type { Session } from 'next-auth'
import type { ParsedUrlQueryInput } from 'querystring'
import type { JsonObject } from 'type-fest'
import { sign as signJwt } from 'jsonwebtoken'
import { azureADSignoutUrl, riseSignoutUri, signOut } from '../components/wrappers/Auth'
import { objectToURLSearchParams } from './routing/searchParams'

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'
export type RequestParams = JsonObject | URLSearchParams

export type JSONResponse<T = unknown> = {
    ok: boolean
    redirected: boolean
    status: number
    statusText: string
    type: ResponseType
    url: string
    headers: Map<string, string>
    data: T
}
interface ErrorResponse {
    errors: { msg: string }[]
}

const apiTarget = process.env.NEXT_PUBLIC_API_TARGET

const fetchWrapper = (url: string, options: RequestInit, timeout = 30000): Promise<Response> =>
    new Promise((resolve, reject) => {
        fetch(url, options).then(resolve).catch(reject)

        if (timeout) {
            setTimeout(reject, timeout)
        }
    })

type RequestParamPrimitive = string | number | boolean | string[] | number[] | boolean[]
function requestParamAsPrimitive(param: unknown): RequestParamPrimitive | null {
    let value: RequestParamPrimitive | null = null
    if (param) {
        if (Array.isArray(param))
            value = param.map((i) => requestParamAsPrimitive(i)).filter((x) => x !== null) as
                | string[]
                | number[]
                | boolean[]
        else if (typeof param === 'string') value = param.trim()
        else if (typeof param === 'object') value = JSON.stringify(param)
        else value = param as number | boolean
    }
    return value
}

const makeRequest = async <T = undefined, D = undefined>(
    url: string,
    method: Method,
    params: RequestParams = {},
    data: D | undefined = undefined,
    headerAdditions: Record<string, string | undefined> = {},
    baseURL = '/api',
    noHeaders = false
): Promise<JSONResponse<T>> => {
    const trimmedParams = Object.entries(params).reduce<ParsedUrlQueryInput>((acc, [key, value]) => {
        const paramValue = requestParamAsPrimitive(value)
        if (paramValue) {
            if (Array.isArray(value)) acc[`${key}[]`] = paramValue
            else acc[key] = paramValue
        }
        return acc
    }, {})

    const queryString = objectToURLSearchParams(trimmedParams)
    const fetchUrl = `${baseURL}${url}${queryString ? `?${queryString}` : ''}`
    const requestOptions: RequestInit = {
        method,
        headers: {
            ...headerAdditions,
        } as RequestInit['headers'],
    }
    if (data instanceof FormData) {
        requestOptions.body = data
    } else if (!noHeaders) {
        ;(requestOptions.headers as Record<string, string>)['Content-Type'] = 'application/json'
        if (data) requestOptions.body = JSON.stringify(data)
    }
    const response = await fetchWrapper(fetchUrl, requestOptions)
    if (!response.ok) {
        if (
            response.status === 403 &&
            window.location.href !== riseSignoutUri &&
            window.location.href !== azureADSignoutUrl
        ) {
            signOut()
        }

        if (response.status === 422) {
            const resp = (await response.json()) as ErrorResponse
            throw new Error(resp.errors.map((e) => e.msg).join(', '))
        }

        if (response.status === 409) throw new Error('A new version of the data is available. Please refresh the page.')

        throw new Error(`Fetch error: ${response.status} ${response.statusText}`)
    }

    let d = undefined as T
    if (response.status === 204) {
        d = '' as T
    } else if (response.headers.get('content-type')?.includes('application/json')) {
        try {
            d = (await response.json()) as T
        } catch {
            // swallow error on empty response
        }
    } else {
        d = (await response.text()) as T
    }

    return {
        ok: response.ok,
        redirected: response.redirected,
        status: response.status,
        statusText: response.statusText,
        type: response.type,
        url: response.url,
        headers: new Map(response.headers),
        data: d,
    }
}

const get = <T>(
    path: string,
    params?: RequestParams,
    headers?: Record<string, string | undefined>,
    baseUrl?: string,
    noHeaders?: boolean
): Promise<JSONResponse<T>> => makeRequest<T>(path, 'GET', params, undefined, headers, baseUrl, noHeaders)

const staticGet = <T>(
    path: string,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => {
    const roles: Auth.UserRoles = { contentPermissions: {}, featurePermissions: {} }
    const buildApiToken = signJwt({ name: 'build', roles }, process.env.JWT_SECRET)
    const buildHeaders = { ...headers, Authorization: `Bearer ${buildApiToken}` }
    return makeRequest<T>(path, 'GET', params, undefined, buildHeaders, apiTarget)
}

const serverGet = <T>(
    path: string,
    session: Session | null,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => {
    const roles: Auth.UserRoles = session?.roles || { contentPermissions: {}, featurePermissions: {} }
    const buildApiToken = signJwt(
        { roles, userId: session?.userId, entityId: session?.entityId },
        process.env.JWT_SECRET
    )
    const buildHeaders = { ...headers, Authorization: `Bearer ${buildApiToken}` }
    return makeRequest<T>(path, 'GET', params, undefined, buildHeaders, apiTarget)
}

const post = <T, D>(
    path: string,
    body: D,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => makeRequest<T, D>(path, 'POST', params, body, headers)

const put = <T, D>(
    path: string,
    body: D,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<T>> => makeRequest<T, D>(path, 'PUT', params, body, headers)

const remove = (
    path: string,
    params?: RequestParams,
    headers?: Record<string, string | undefined>
): Promise<JSONResponse<undefined>> => makeRequest(path, 'DELETE', params, undefined, headers)

export { makeRequest, get, staticGet, serverGet, post, put, remove }
