import type { UnitType, ManipulateType, Dayjs } from 'dayjs'
import { inflate } from 'pako'
import * as dates from '../../shared/utils/dates'
import { formatNumber } from './math'

export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1)

export const formatMinutes = (minutes: number): string => {
    const min = Math.floor(minutes)
    const seconds = Math.floor((minutes - min) * 60)
    return `${min}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const formatHours = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600)
    const min = Math.floor((seconds % 3600) / 60)
    const sec = Math.floor(seconds % 60)
    return `${hours}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
}

export const money = (
    value: number | bigint,
    displayTrailingZero = false,
    precision: number | undefined = undefined,
    isCompact = false
): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        notation: isCompact ? 'compact' : 'standard',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        currencySign: 'accounting',
    })
    let moneyStr = formatter.format(value)
    if (!displayTrailingZero) moneyStr = moneyStr.replace('.00', '')
    return moneyStr
}

export const ordinalNumberSuffix = (num: number): string => {
    if (num > 3 && num < 21) return 'th'
    switch (num % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}

export const formatHeight = (heightInInches: number, precision = 0): string => {
    const feet = Math.floor(heightInInches / 12)
    const inches = heightInInches - feet * 12
    const roundedInches = formatNumber(inches, precision)
    if (roundedInches === '12') return `${feet + 1}'0"`
    if (roundedInches) {
        return `${feet}'${roundedInches}"`
    }
    return `${feet}'${inches}"`
}

export const formatString = (val: number | string | null, description: string): string | null => {
    if (!val) return null
    return `${val}${description}`
}

export const formatSimilarPlayersMech = (value: string | null): string => {
    switch (value) {
        case 'Bird Exception':
            return 'Bird'
        case 'Rookie Scale Exception':
            return 'Rookie Scale'
        case 'Rookie Scale Extension':
            return 'Rookie Ext'
        case 'Mid-Level Exception':
            return 'MLE'
        case 'Mid-Level Exception - Pre-2011':
            return 'MLE'
        case 'Mid-Level Exception - Convertible':
            return 'MLE'
        case 'Min Salary Exception':
            return 'Min'
        case 'Mid-Level Exception - Non-TP':
            return 'MLE'
        case null:
            return ''
        default:
            return value
    }
}

export const formatGameMatchupString = (
    awayTeamAbbr: string | null,
    homeTeamAbbr: string | null,
    awayTeamId: string | null,
    playerTeamId: string | null
): string | null =>
    awayTeamAbbr && homeTeamAbbr && awayTeamId && playerTeamId
        ? `${awayTeamId === playerTeamId ? awayTeamAbbr : homeTeamAbbr} ${awayTeamId === playerTeamId ? '@' : 'vs'} ${
              awayTeamId === playerTeamId ? homeTeamAbbr : awayTeamAbbr
          }`
        : null

export const hedgeProjections = (
    value: number | null | undefined,
    precision = 1,
    showPercentSign = true
): string | null => {
    if (!value && value !== 0) return null
    if (value < 0.01) {
        return showPercentSign ? '<1%' : '<1'
    }
    if (value > 0.99) {
        return showPercentSign ? '>99%' : '>99'
    }
    return formatNumber(value, precision, 'percent')?.replace('%', showPercentSign ? '%' : '') || null
}

export const getSortingLastName = (name: string | null | undefined): string | null => {
    if (!name) return null
    const names = name
        .replace(', Jr.', '')
        .replace(', Sr.', '')
        .replace(' III', '')
        .replace(' II', '')
        .replace(' von ', ' von')
        .replace(/ \([\s\S]*?\)/g, ' ')
        .split(' ')
        .filter((d) => d !== ' ')

    return names.length && names.length > 1 ? names[names.length - 1] : ''
}
export const getTeamAbbrFallback = (teamName: string): string =>
    teamName
        .replace(/[^a-zA-Z0-9]/g, '')
        .substring(0, 4)
        .toUpperCase()

export function firstInitialLastName(fullName: string): string {
    const names = fullName.split(' ')
    if (names.length <= 1) return fullName
    const [firstName, ...otherNames] = names
    return [`${firstName.substring(0, 1)}.`, ...otherNames].join(' ')
}

/** Date Formatting */
export const {
    isSameOrAfterDate,
    isSameOrAfterToday,
    isAfter,
    isBefore,
    isToday,
    formatDateString,
    fromNow,
    dateInTimezone,
    addDate,
    isTodaySameOrAfter,
    formatSeason,
    seasonAsNumber,
} = dates

export const dateDiff = (date: string, unit: UnitType, diff: number): boolean => dates.diff(date, unit) >= diff
export const dateDiffFloat = (date: string, unit: UnitType, float: boolean, startDate?: string): number =>
    dates.diff(date, unit, startDate, float)

export const dateAdd = (date: string | undefined, unit: ManipulateType, num: number): Dayjs =>
    dates.addDate(date, num, unit)

export const dateStringtoDayJs = (dateString: string): Dayjs => dates.parse(dateString)

export function formatMockDraftRange(mdr: DTO.MockDraftRange | null | undefined): string
export function formatMockDraftRange(pickHigh: number | null | undefined, pickLow: number | null | undefined): string
export function formatMockDraftRange(
    pickHighOrMDR: DTO.MockDraftRange | number | null | undefined,
    pickLowOrUndefined?: number | null | undefined
): string {
    let pickHigh: number | null | undefined
    let pickLow: number | null | undefined
    if (pickHighOrMDR && typeof pickHighOrMDR === 'object') {
        pickHigh = pickHighOrMDR.pickHigh
        pickLow = pickHighOrMDR.pickLow
    } else {
        pickHigh = pickHighOrMDR
        pickLow = pickLowOrUndefined
    }
    return pickHigh ? `${pickHigh}\u2013${pickLow || 'UD'}` : 'UD'
}

export const formatDistance = (distance: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        notation: 'compact',
    })
    return formatter.format(distance)
}

export const unZipResponse = <T>(base64str: string): T => {
    const strData = window.atob(base64str)
    const charData = strData.split('').map((x) => x.charCodeAt(0))
    const binData = new Uint8Array(charData)
    return JSON.parse(inflate(binData, { to: 'string' })) as T
}
